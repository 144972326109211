<template>
    <v-sheet class="fill-height" color="transparent">
        <v-lazy
            :v-model="user.costs"
            :options="{
                threshold: 0.5,
            }"
            class="fill-height"
        >
            <v-card
                style="background-color: #fafafa; border-radius: 10px"
                flat
                class="ma-0 pa-0 fill-height"
            >
                <p
                    v-if="user.name == 'On Hold'"
                    class="ma-0 pa-3  pb-1 d-flex font-weight-bold"
                    style="color: #EDC43B;"
                >
                    ON HOLD
                </p>
                <v-row v-else class="d-flex ma-0 pa-0">
                    <v-col>
                        <v-row>
                            <v-avatar class="ml-3 mt-3 pa-0" size="28">
                                <v-img
                                    v-if="profilePicUrl"
                                    :src="profilePicUrl"
                                    :alt="user.name"
                                />
                                <v-icon v-else x-large color="#707070"
                                    >mdi-account-circle</v-icon
                                >
                            </v-avatar>
                            <div class="ma-0 pa-2 pb-1">
                                <p class="ma-0 pa-0 font-weight-bold">
                                    {{ user.name }}
                                </p>
                                <p class="ma-0 pa-0 mt-n1">
                                    {{ user.role }}
                                </p>
                            </div>
                            <v-spacer></v-spacer>
                            <v-tooltip>
                                <template v-slot:activator="{ on, attrs }">
                                    <p
                                        class="text-center font-weight-black mt-4 mr-2 "
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        {{
                                            availableHours
                                                ? availableHours.toFixed(1) +
                                                  'h'
                                                : ''
                                        }}
                                    </p>
                                </template>
                                <span>Available Hours</span>
                            </v-tooltip>
                            <v-tooltip>
                                <template v-slot:activator="{ on, attrs }">
                                    <p
                                        class="text-center font-weight-black mt-4"
                                        :class="{
                                            'green--text':
                                                totalHours <= availableHours,
                                            'red--text':
                                                totalHours > availableHours,
                                        }"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        {{
                                            totalHours
                                                ? totalHours.toFixed(1) + 'h '
                                                : ''
                                        }}
                                    </p>
                                </template>
                                <span>Working hours</span>
                            </v-tooltip>
                            <v-btn
                                x-small
                                icon
                                color="primary"
                                class="ml-2 mt-4 mr-3 pt-0 "
                                @click="openUserHours"
                            >
                                <v-icon class="mt-0">
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </v-row>

                        <v-row>
                            <p
                                class="ml-4 mt-3 pa-0"
                                v-html="weekDistribution"
                            ></p>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="flex-nowrap ma-0 pa-0">
                    <draggable
                        :disabled="loading"
                        class="overflow-y-auto px-2 py-0 ma-0"
                        :style="
                            `height:${height -
                                (user.id == '123' ? 68 : 140)}px; width: 100%`
                        "
                        v-model="user.costs"
                        group="costs"
                        @change="draggableLog"
                    >
                        <div
                            class="py-0 mx-0 my-3 px-1"
                            v-for="(cost, index) in userCosts"
                            :key="index"
                        >
                            <v-sheet
                                min-height="100"
                                class="fill-height"
                                color="transparent"
                            >
                                <v-lazy
                                    :v-model="cost"
                                    :options="{
                                        threshold: 0.5,
                                    }"
                                >
                                    <CostTask
                                        :cost="cost"
                                        :settings="settings"
                                        :engineeringHours="
                                            user.engineeringHours
                                        "
                                        :dateTodaySeconds="dateTodaySeconds"
                                        @openAssignEngineer="openAssignEngineer"
                                        @openUnsetEngineering="
                                            openUnsetEngineering
                                        "
                                    />
                                </v-lazy>
                            </v-sheet>
                        </div>
                    </draggable>
                </v-row>
            </v-card>
        </v-lazy>
        <!--USER ASSIGN-->
        <v-dialog persistent max-width="400px" v-model="selectEngineer">
            <AssingEngineer
                v-if="selectEngineer"
                :costId="selectedCost"
                :quoteId="selectedQuote"
                :users="users"
                @removeEngineer="removeEngineer"
                @close="closeAssignEngineer"
            />
        </v-dialog>

        <!--USER HOURS-->
        <v-dialog persistent max-width="330px" v-model="editUserHours">
            <AssignUserHours
                v-if="editUserHours"
                :user="user"
                @updateUserHours="updateUserHours"
                @close="closeUserHours"
            />
        </v-dialog>
        <!--Get off engineering-->
        <v-dialog
            v-model="returnForm"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="returnForm">
                <v-card-title v-if="returnForm" class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeUnsetEngineering"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    Return From Engineering</v-card-title
                >
                <v-card-text>
                    <strong>Reason</strong>
                    <v-text-field
                        v-model="returnedReasons"
                        hide-details
                        label="Return reasons*"
                        :rules="[rules.required]"
                        required
                    >
                    </v-text-field>
                </v-card-text>
                <v-card-text>
                    Are you sure you want to return from engineering?
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        @click="unSetEngineering"
                        color="primary"
                        rounded
                        :loading="loading"
                    >
                        RETURN
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-sheet>
</template>

<script>
import API from '@/services/api'
import draggable from 'vuedraggable'
import { loadImage } from '@/helpers/imageHandler'
import { mapMutations } from 'vuex'
import moment from 'moment'

export default {
    name: 'CostUserDraggable',
    components: {
        draggable,
        CostTask: () => import('@/components/Engineering/CostTask.vue'),
        AssingEngineer: () =>
            import('@/components/Engineering/AssignEngineer.vue'),
        AssignUserHours: () =>
            import('@/components/Engineering/AssignUserHours.vue'),
    },
    props: {
        user: {
            type: Object,
            default: () => {},
        },
        settings: {
            type: Array,
            default: () => [],
        },
        users: {
            type: Array,
            default: () => [],
        },
        height: Number,
    },
    data() {
        return {
            companyId: JSON.parse(localStorage.getItem('company')),
            folderUsersPictures: 'users_pictures',
            profilePicUrl: null,
            selectEngineer: false,
            editUserHours: false,
            selectedCost: undefined,
            selectedQuote: undefined,
            backUp: [],
            week: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
            ],
            days: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ],
            totalHours: 0,
            today: '',
            todayName: '',
            availableHours: 0,
            costsForToday: [],
            hoursForToday: 0,
            nextSundayTimestamp: 0,
            dateToday: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10),
            dateTodaySeconds:
                (Date.now() - new Date().getTimezoneOffset() * 60000) / 1000,
            loading: false,
            returnForm: false,
            rules: {
                required: v => !!v || 'Required',
            },
            returnedReasons: '',
        }
    },
    computed: {
        userCosts() {
            let costs = this.user.costs
            costs = this.user.costs.filter(cost =>
                cost.engineer ? cost.engineer == this.user.id : false
            )
            if (this.user.id != '123' && this.user.id != '') {
                costs.sort((a, b) => a.indexAssign - b.indexAssign)
            } else {
                costs.sort(
                    (a, b) =>
                        a.engineeringDate._seconds - b.engineeringDate._seconds
                )
            }

            return costs
        },
        weekDistribution() {
            let text = ''
            this.week.forEach(day => {
                text =
                    text +
                    ` <strong style="color:${
                        (this.user.userWeekHours &&
                        this.user.userWeekHours[day.toLowerCase()] > 0
                            ? this.user.userWeekHours[day.toLowerCase()]
                            : 0) >= this.totalHoursToday
                            ? 'black'
                            : day == this.todayName
                            ? 'red'
                            : 'black'
                    }">
                             ${day.substring(0, 3) +
                                 ': '} </strong> <span style="font-weight: normal"> ${
                        this.user.userWeekHours &&
                        this.user.userWeekHours[day.toLowerCase()] > 0
                            ? this.user.userWeekHours[day.toLowerCase()]
                            : 0
                    }${
                        day == this.todayName && this.totalHoursToday
                            ? `/${this.totalHoursToday.toFixed(0)}`
                            : ``
                    }</span>
                    `
            })
            return text
        },
    },
    watch: {
        userCosts: function() {
            let costs = this.user.costs ? this.user.costs : []
            let engineeringHours = this.user.engineeringHours
                ? this.user.engineeringHours
                : {}
            let totalHours = 0
            costs.forEach(cost => {
                if (
                    cost.category in engineeringHours &&
                    cost.engineeringDate._seconds <= this.dateTodaySeconds
                ) {
                    totalHours +=
                        Number(engineeringHours[cost.category].hours) /
                        Number(engineeringHours[cost.category].count)
                } else if (
                    cost.engineeringDate._seconds <= this.nextSundayTimestamp
                ) {
                    totalHours += 2
                }
            })

            this.totalHours = totalHours

            const costsForToday = this.userCosts.filter(
                cost =>
                    this.formatDate2(cost.engineeringDate._seconds) <=
                    this.formatDate2(this.dateTodaySeconds)
            )
            let totalHoursToday = 0
            costsForToday.forEach(cost => {
                if (cost.category in engineeringHours) {
                    totalHoursToday +=
                        Number(engineeringHours[cost.category].hours) /
                        Number(engineeringHours[cost.category].count)
                } else {
                    totalHoursToday += 2
                }
            })
            this.totalHoursToday = totalHoursToday
        },

        'user.userWeekHours': function() {
            this.getAvailableHours()
        },
        costsForToday: function() {
            this.hoursForToday
        },
    },
    async mounted() {
        try {
            if (!this.user.userWeekHours) {
                this.$set(this.user, 'userWeekHours', {})
            }
            if (this.user.picture) {
                try {
                    const path = `${this.companyId}/${this.folderUsersPictures}`
                    this.profilePicUrl = await loadImage(
                        path,
                        this.user.picture
                    )
                } catch (error) {
                    console.log(error.message)
                }
            }

            this.today = new Date()
            const day = this.today.getDay()
            this.todayName = this.days[day]
            if (this.user.userWeekHours) {
                this.getAvailableHours()
            }
            this.nextSundayTimestamp = this.getNextSundayTimestamp()
        } catch (error) {
            console.log(error)
            // this.setItemsError(error.message)
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        openAssignEngineer(params) {
            const costId = params.costId ? params.costId : ''
            const quoteId = params.quoteId ? params.quoteId : ''
            this.selectedCost = costId
            this.selectedQuote = quoteId
            this.selectEngineer = true
        },
        openUnsetEngineering(params) {
            const costId = params.costId ? params.costId : ''
            const quoteId = params.quoteId ? params.quoteId : ''
            this.selectedCost = costId
            this.selectedQuote = quoteId
            this.returnForm = true
        },
        closeUnsetEngineering() {
            this.selectedCost = ''
            this.selectedQuote = ''
            this.returnForm = false
        },
        closeAssignEngineer() {
            this.selectEngineer = false
            this.selectedCost = undefined
            this.selectedQuote = undefined
        },
        openUserHours() {
            this.editUserHours = true
        },
        closeUserHours() {
            this.editUserHours = false
        },
        formatDate2(seconds) {
            return moment.unix(seconds).format('YYYYMMDD')
        },
        getNextSundayTimestamp() {
            const now = new Date() // Fecha y hora actuales
            const dayOfWeek = now.getDay() // Día de la semana (0 = Domingo, 1 = Lunes, ..., 6 = Sábado)

            const daysUntilSunday = (7 - dayOfWeek) % 7 // Días que faltan para el próximo domingo
            const nextSunday = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + daysUntilSunday
            )
            nextSunday.setHours(23, 59, 59, 999)

            return nextSunday.getTime() / 1000 // Timestamp en milisegundos
        },

        draggableLog: async function(evt) {
            try {
                if (evt.added) {
                    this.loading = true
                    let index = evt.added.newIndex
                    let beforeIndex = 0
                    let afterIndex = 0
                    if (index > 0) {
                        beforeIndex = this.user.costs[index - 1].indexAssign
                    }

                    if (index < this.user.costs.length - 1) {
                        afterIndex = this.user.costs[index + 1].indexAssign
                    } else if (index == this.user.costs.length - 1) {
                        afterIndex = beforeIndex + 1
                    }
                    this.user.costs[index].indexAssign =
                        (beforeIndex + afterIndex) / 2
                    const params = await API.setEngineer(
                        evt.added.element.quoteId,
                        evt.added.element.document_id,
                        {
                            engineer:
                                this.user.name == 'On Hold' ? '' : this.user.id,
                            indexAssign:
                                this.user.name == 'On Hold'
                                    ? 1
                                    : this.user.costs[index].indexAssign,
                        }
                    )

                    let cost = evt.added.element
                    cost.engineer =
                        params.engineer && params.engineer != ''
                            ? params.engineer
                            : '123'
                    cost.updatedOn = params.updatedOn
                    this.loading = false
                }
                if (evt.moved) {
                    this.loading = true
                    const index = evt.moved.newIndex
                    let beforeIndex = 0
                    let afterIndex = 0
                    if (index > 0) {
                        beforeIndex = this.user.costs[index - 1].indexAssign
                    }

                    if (index < this.user.costs.length - 1) {
                        afterIndex = this.user.costs[index + 1].indexAssign
                    } else if (index == this.user.costs.length - 1) {
                        afterIndex = beforeIndex + 1
                    }
                    this.user.costs[index].indexAssign =
                        (beforeIndex + afterIndex) / 2

                    await API.updateCosts(evt.moved.element.quoteId, {
                        id: this.user.costs[index].document_id,
                        indexAssign: this.user.costs[index].indexAssign,
                    })
                    this.$emit('emitCost', {
                        cost: this.user.costs[index],
                    })
                    this.loading = false
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        formatDate(seconds = undefined) {
            return `${moment.unix(seconds).format('YYYY-MM-DD')}`
        },
        removeEngineer(cost) {
            const index = this.user.costs.findIndex(
                c => c.document_id == cost.id
            )

            if (index >= -1) {
                let selectedCost = this.user.costs[index]
                selectedCost.engineer =
                    cost.engineer && cost.engineer != '' ? cost.engineer : '123'
                this.user.costs.splice(index, 1)
                this.$emit('emitCost', selectedCost)
            }
        },
        updateUserHours(params) {
            if (params.userWeekHours) {
                this.user.userWeekHours = params.userWeekHours
            }
        },
        getAvailableHours() {
            let availableHours = 0
            const indexToday = this.week.findIndex(d => d == this.todayName)
            const indexDay = this.week.findIndex(d => d == 'Sunday')

            let subWeek = this.week.slice(indexToday, indexDay + 1)
            if (indexToday == indexDay) subWeek = [this.week[indexDay]]
            if (subWeek.length > 0) {
                subWeek.forEach(day => {
                    let hours = this.user.userWeekHours[day.toLowerCase()]
                    if (day.toLowerCase() == this.todayName.toLowerCase()) {
                        const aux =
                            1630 - parseInt(moment(new Date()).format('HHmm'))
                        hours =
                            aux / 100 > hours ? hours : aux < 0 ? 0 : aux / 100
                    }
                    if (
                        this.user.userWeekHours &&
                        typeof this.user.userWeekHours == 'object' &&
                        Object.keys(this.user.userWeekHours).length > 0
                    ) {
                        availableHours = availableHours + hours
                    } else {
                        availableHours = availableHours + 0
                    }
                })
            }
            this.availableHours = availableHours
        },
        async unSetEngineering() {
            try {
                this.loading = true
                const cost = await API.returnEngineering(
                    this.selectedQuote ? this.selectedQuote : '',
                    this.selectedCost ? this.selectedCost : '',
                    {
                        engineering: false,
                        engExitBy: this.user.id,
                        returnedReasons: this.returnedReasons,
                    }
                )
                this.$emit('removeCost', {
                    costId: cost.id,
                    quoteId: cost.quoteId,
                    engineer: this.user.id,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.closeUnsetEngineering()
            }
        },
    },
}
</script>
<style scoped>
.borderRounded {
    border-radius: 15px;
}
.underlineTag {
    text-decoration: underline #3277d1;
    font-weight: bold;
    font-size: 13px;
    color: #3277d1;
}
</style>
